<script>
import EnfantPreview from '@/components/presentation/enfantPreview.vue'
import AvatarSelection from '@/components/dialogs/profile/avatarSelection'
import ProfilEdition from '@/components/dialogs/profile/profilEdition'
import StatsPreview from '@/components/statistiques/statsPreview.vue'
import Moyenne from '@/components/statistiques/moyenne'

export default {
  name: 'profil',
  components: {
    EnfantPreview,
    AvatarSelection,
    ProfilEdition,
    StatsPreview,
    Moyenne
  },
  data () {
    return {
      courriel: null
      // user: null
    }
  },
  computed: {
    user () {
      return this.$store.state.User.profile
    },
    showFusion: {
      set (val) { this.$store.commit('Dialogs/setFusionCompte', val) },
      get () { return this.$store.state.Dialogs.showFusionCompte }
    },
    showAvatarSelection: {
      set (val) { this.$store.commit('Dialogs/setAvatarSelection', val) },
      get () { return this.$store.state.Dialogs.showAvatarSelection }
    },
    showCodeConfirmation: {
      set (val) { this.$store.commit('Dialogs/setCodeConfirmation', val) },
      get () { return this.$store.state.Dialogs.showCodeConfirmation }
    },
    showProfilEdition: {
      get () { return this.$store.state.Dialogs.showProfilEdition },
      set (val) { this.$store.commit('Dialogs/setProfilEdition', val) }
    }
  },
  apollo: {
    elementsMoisCourant: {
      query: require('@/graphql/queries/v2/calendrierCalendrierElements.gql'),
      variables () {
        const date = new Date()
        const dat = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        return {
          filtre: {
            fin: dat
          }
        }
      },
      update (data) {
        // COMME ON REGROUPE PAR BATCH
        // TODO UNE REQUËTE POUR RENVOYER DES ELEMENTS UNIQUES ACTUELLEMENTS ON A DES DOUBLONS
        return data.calendrierCalendrierElements
      },
      watchLoading (isLoading) {
        this.loadElem = isLoading
      }
    }
  },
  mounted () {
  },
  methods: {
    formatDate (date) {
      // const temp = date.split(' ')
      // const dateFormat = temp[0] + 'T' + temp[1] + temp[2]
      return new Date(date).toLocaleString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' })
    }
  }
}
</script>

<template lang='pug'>
  .profil-index.split-page(v-if='user')
    avatar-selection
    profil-edition
    .page-header(color='secondary')
      .header__inner-content
        //- v-btn.white--text.mb2(to='/', rounded, color='black')
          font-awesome-icon.mr2.f4(:icon="['fas', 'arrow-left']")
          span Retour
        //- div.flex-grow-1
        .header-title(style='line-height: 1.25')
          div.main-title Profil parent
          //- div {{user.prenom}} {{user.nom}}

    v-alert.fusion-notice.secondary--text(border='left', colored-border, icon="mdi-twitter", color='primary')
      template(v-slot:prepend)
        font-awesome-icon.ml2.mr3.f3(:icon="['fad', 'people-arrows']")
      v-row(align='center')
        v-col.f6(style='padding: 0 12px') Si vous avez plusieurs comptes parent, vous pouvez les fusionner pour rassembler vos informations.
        v-col.shrink(style='padding: 0 12px')
          v-btn(small, rounded, color='primary', @click='showFusion = !showFusion') Fusionner mes comptes
    div.page__main-content
      section.large-section
        .section-subtitle__header
          h2.section-subtitle Informations générales
        v-sheet.section-card.relative(light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
          v-btn(@click='showProfilEdition = !showProfilEdition', style='position: absolute; top: 5px; right: 5px;', icon)
            font-awesome-icon.f5.secondary--text(:icon="['fad', 'pen']")

          div.flex
            div.mr2
              v-hover(v-slot:default="{ hover }")
                v-avatar.light.relative.pointer(size='70', height='70', width='70', @click='showAvatarSelection = !showAvatarSelection', style='overflow: visible;')
                  v-img(v-if='$store.state.User.profile && $store.state.User.profile.avatar', contain, :src='$store.state.User.profile.avatar.imageUrl')
                  div.f4.ttu(v-else-if='user.info && user.info.prenom && user.info.nom') {{user.info.prenom[0]}}.{{user.info.nom[0]}}.
                  img(v-else, src='~@/assets/avatars/avatar_afro.png')
                  div.edit-icon.animated.fadeIn(v-if='hover')
                    font-awesome-icon.f6(:icon="['fad', 'pen']")

            div.ml2.w-100
              div.f7.secondary--text(v-if='user.createdAt') Inscription le {{formatDate(user.createdAt)}}
              div.f3.mt2(v-if='user.info && user.info.nom && user.info.prenom') {{user.info.prenom}} {{user.info.nom}}
              div.i.f6(v-if='user.info && user.info.username') @{{user.info.username}}
              v-divider.mv2
              div.flex.flex-column
                div.inline-flex.mt2
                  font-awesome-icon.f5.secondary--text.mr2(:icon="['fad', 'at']")
                  div(v-if='user.info.courriel') {{user.info.courriel}}
                div.inline-flex.mt2
                  font-awesome-icon.f5.secondary--text.mr2(:icon="['fad', 'birthday-cake']")
                  div(v-if='user.info.courriel') {{formatDate(user.info.dateNaissance)}}
        .section-subtitle__header.mt3
          h2.section-subtitle Comptes associés
        v-sheet.section-card(:color='$store.state.Preferences.darkMode ? "light" : "white"', style='padding: 0')
          v-list.main-menu__list.light-list(v-if='$store.state.User.enfantsLoading')
            template
              v-skeleton-loader.w-100(type="list-item-avatar-two-line")
              v-skeleton-loader.w-100(type="list-item-avatar-two-line")

          v-list.light-list(v-else, rounded, style='padding: 0')
            template(v-for='(recu, index) in $store.state.User.relations')
              enfant-preview(:user='recu.inferieur')
              v-divider(v-if='index < $store.state.User.relations.length - 1')
      section.small-section
        .section-subtitle__header
          h2.section-subtitle Paramètres
        v-sheet.section-card(light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
          v-btn(block, large, style='white-space: normal;', rounded, outlined, @click='showCodeConfirmation = true') Obtenir les identifiants de mes élèves
          //- v-btn.mt3(block, large, style='white-space: normal;', rounded, outlined) Autre action chouette
        //- .section-subtitle__header.mt3
          h2.section-subtitle Statistiques du compte
        //- v-sheet.section-card(light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
          ul
            li.pt2
              router-link(to='/calendrier') 15 événements ce mois-ci
        .section-subtitle__header.mt3
          h2.section-subtitle Statistiques
          v-btn(small, outlined, rounded, @click='', disabled) Plus de statistiques
        div
          stats-preview()
            template(slot='numberStatsFirstRow')
              router-link.stat.stat-link(to='/enfants')
                v-avatar(size='30', color='transparent')
                  font-awesome-icon.f4(:icon="['fad', 'graduation-cap']")
                div.stat-number {{$store.state.User.totRel ? $store.state.User.totRel : 'N/D'}}
                div.stat-label {{$store.state.User.totRel > 1 ? 'Enfants' : 'Enfant'}}
              router-link.stat.stat-link(to='/calendrier')
                v-avatar(size='30', color='transparent')
                  font-awesome-icon.f4(:icon="['fad', 'calendar-alt']")
                div.stat-number {{(elementsMoisCourant && elementsMoisCourant.total) ? elementsMoisCourant.total : 'N/D'}}
                div.stat-label {{(elementsMoisCourant && elementsMoisCourant.total > 1) ? 'Événements ce mois-ci' : 'Événement ce mois-ci'}}

            //- template(slot='numberStatsSecondRow')
              .stat-list
                router-link.stat-list-item.stat-link(to='/calendrier')
                  .stat-list-icon
                    v-avatar(size='30', color='transparent')
                      font-awesome-icon.f4(:icon="['fad', 'calendar-alt']")
                  .stat-list-content
                    div.stat-number {{(elementsMoisCourant && elementsMoisCourant.total) ? elementsMoisCourant.total : 'N/D'}}
                    div.stat-label {{(elementsMoisCourant && elementsMoisCourant.total > 1) ? 'Événements ce mois-ci' : 'Événement ce mois-ci'}}
              //- div.stat(v-if='usagersGroupe.usagers.total')
                span.stat-number() {{usagersGroupe.usagers.total}}
                div.stat-label Élève{{usagersGroupe.usagers.total > 1 ? "s" : ""}}
</template>
<style lang='sass'>
@import 'src/styles/pages/_profil'

</style>
