<script>
export default {
  name: 'ModalAvatarSelection',
  computed: {
    showAvatarSelection: {
      set (val) { this.$store.commit('Dialogs/setAvatarSelection', val) },
      get () { return this.$store.state.Dialogs.showAvatarSelection }
    },
    userAvatar: {
      get () { return this.$store.state.User.userAvatar },
      set (val) { this.$store.commit('User/setUserAvatar', val) }
    },
    filteredAvatars () {
      if (this.avatars) {
        return this.avatars
      } else {
        return null
      }
    }
  },
  data () {
    return {
      loading: false,
      avatar: false,
      selectedAvatar: null,
      snackbar: false
    }
  },
  apollo: {
    avatars: {
      query: require('@/graphql/queries/v2/communImagesAvatars.gql'),
      update (data) {
        return data.communImagesAvatars
      },
      loadingKey: 'loading',
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  },
  methods: {
    async selectAvatar (avatar) {
      if (this.selectedAvatar !== avatar.id) {
        this.snackbar = true
        this.selectedAvatar = avatar.id
        this.userAvatar = avatar
        try {
          await this.$apollo.mutate({
            mutation: require('@/graphql/mutations/v2/updateSalleClasseUsager.gql'),
            variables: {
              attrs: {
                role: 'SALLE_CLASSE_ENSEIGNANT',
                avatar: avatar.id
              }
            }
          }).then(({ data }) => {
            if (!data.updateSalleClasseUsager || data.updateSalleClasseUsager.errors) {
              throw data ? data.updateSalleClasseUsager.errors : 'erreur lors de la mutation'
            } else {
              this.$store.dispatch('User/fetchMinProfile')
            }
          })
        } catch (e) {
          console.error(e)
        }
      }
    }
  }
}
</script>
<template lang="pug">
  v-dialog(v-model='showAvatarSelection', content-class='custom-dialog', max-width='1000', scrollable)
    v-snackbar(v-model='snackbar', :timeout='5000', fixed, bottom, color='success')
      | Le changement prendra effet lors du prochain rafraîchissement de la page.
    v-card.relative.light
      v-hover(v-slot:default="{ hover }")
        v-btn.dialog-button(@click='showAvatarSelection = false', depressed, fab, x-large, color='primary')
          font-awesome-icon.f3(:icon="['fas', 'times']")
      v-card-title.dialog-title.flex-column.secondary.white--text(style='margin-bottom: 0')
        div.f3 Sélectionner un avatar
      v-card-text.dark--text(style='padding-top: 30px')
        div.dialog-text
          //- div.f3.mb3 Choisissez un avatar ci-dessous.
          template(v-if='loading')
            div Chargement des avatars
          template(v-else)
            .avatars__wrapper
              v-lazy(:options="{ threshold: .75 }", v-for='(avatar, index) in filteredAvatars', :key='index')
                .avatar-card(:class='{"selected-avatar" : selectedAvatar === avatar.id}', @click='selectAvatar(avatar)')
                  v-img(v-if='avatar && avatar.imageUrl', contain, :src='avatar.imageUrl')
                  div(v-else)
                    font-awesome-icon.f1(:icon="['fad', 'grin-tongue-squint']")
              v-lazy
                .avatar-card(:class='{"selected-avatar" : !selectedAvatar}', @click='selectAvatar()')
                  v-img(v-if='avatar && avatar.imageUrl', contain, :src='avatar.imageUrl')
                  div(v-else)
                    //- pre {{$store.state.User.profile}}
                    div.f1(v-if='$store.state.User.profile && $store.state.User.profile.info && $store.state.User.profile.info.prenom') {{$store.state.User.profile.info.prenom[0]}}.{{$store.state.User.profile.info.nom[0]}}.
                    font-awesome-icon.f1(v-else, :icon="['fas', 'times']")
      //- v-btn.secondary--text(@click='set', color='primary', depressed, :disabled='!pseudoToFusion || !codeToFusion || loading || finish', block, x-large)
        span.f4 Fusionner
</template>

<style lang='sass' scoped>
  .avatars__wrapper
    width: 100%
    margin: 0 auto
    padding: 40px 0
    display: grid
    // grid-auto-rows: 210px
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr))
    grid-gap: 30px
    justify-content: center
    align-content: space-between
    // border: solid 1px white
    > div
      height: 200px
      align-self: start
      display: flex
      justify-content: center
      align-items: center
      padding-bottom: 10px
      .avatar-card
        background-color: white
        width: 150px
        height: 150px
        border-radius: 100px
        text-align: center
        position: relative
        overflow: hidden
        display: flex
        justify-content: center
        align-items: center
        overflow: hidden
        > div
          transition: all 0.5s $custom-ease-in-out
          transform: scale(0.75)
        &:hover
          border: solid 3px black
          > div
            transform: scale(1)
        &.selected-avatar
          border: solid 10px black !important
    @media all and (max-width: $small)
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr))
      grid-gap: 30px
      > div
        height: 100px
        .avatar-card
          width: 80px
          height: 80px
</style>
