<script>
import ChatControls from '@/mixins/chatControls.js'
import ScoreFunctions from '@/mixins/scoreFunctions.js'
import FormatDate from '@/mixins/formatDate.js'
import ConnectToEnfant from '@/mixins/connectEleve.js'
// import noteEnfant from '@/components/eleves/noteEnfant'

export default {
  name: 'ElevePreview',
  mixins: [ChatControls, ScoreFunctions, FormatDate, ConnectToEnfant],
  components: {
    // noteEnfant
  },
  props: {
    user: { type: Object, required: true }
  },
  data () {
    return {
      loadingCalc: true,
      markGlobal: 0,
      userStat: null,
      listeExo: [],
      loading: false
    }
  },
  // apollo: {
  //   user: {
  //     // TODO CHANGER POUR SALLECLASSEUSAGER.gql
  //     query: require('@/graphql/queries/v2/salleClasseUsager.gql'),
  //     variables () {
  //       return {
  //         id: this.id
  //       }
  //     },
  //     update (data) {
  //       this.loading = false
  //       return data.salleClasseUsager
  //     },
  //     watchLoading (isLoading) {
  //       this.loading = isLoading
  //     },
  //     skip () {
  //       return !this.id
  //     }
  //   },
  //   userStat: {
  //     query: require('@/graphql/queries/plus/userNote.gql'),
  //     client: 'plus',
  //     variables () {
  //       return {
  //         id: this.id,
  //         statuts: ['FINISHED'],
  //         limit: 100
  //       }
  //     },
  //     update (data) {
  //       this.calcMoyenne(data)
  //       return data.user
  //     }
  //   }
  // },
  methods: {
    // calcMoyenne (data) {
    //   this.loadingCalc = true
    //   let mark = 0
    //   this.listeExo = []
    //   // const nb_elem = data.user.exercice_graphs_classe_maison.length + data.user.exercice_graphs.length
    //   data.user.exercice_graphs_classe_maison.forEach((e) => {
    //     if (e.mark && !e.graph.tag_invisibles.find(t => t.name.inlucdes('Saglac' || t.name.inlucdes('Saguenay')))) {
    //       mark += e.mark
    //       this.listeExo.push(e)
    //     }
    //   })
    //   data.user.exercice_graphs.forEach((e) => {
    //     if (e.mark) {
    //       mark += e.mark
    //       this.listeExo.push(e)
    //     }
    //   })
    //   if (mark > 0) {
    //     this.markGlobal = Math.round(mark / this.listeExo.length, 2)
    //   } else {
    //     this.markGlobal = 0
    //   }
    //   this.loadingCalc = false
    // },
  }
}
</script>

<template lang='pug'>
div.eleve-preview()
  template(v-if='loading')
    v-list-item.eleve-card__main-content(depressed, flat)
      v-skeleton-loader.w-100(type="list-item-avatar-three-line")
  template(v-else-if='user')
    v-list-item.eleve-card__main-content.animated.fadeIn(depressed, flat)
      v-list-item-avatar.mr3(size='35', style='margin-right:10px', color='light', v-if='!$store.state.App.mobileTemplate')
        img(v-if='user && user.avatar && user.avatar.imageUrl', :src='user.avatar.imageUrl')
        div.secondary--text.f7.ttu(v-else-if='user.info.prenom && user.info.nom') {{user.info.prenom[0]}}.{{user.info.nom[0]}}.
      v-list-item-content
        v-list-item-subtitle.o-80(v-if='user.last_sign_in_at') Dernière connexion: {{formatDate(user.last_sign_in_at.split(' ')[0])}}
        v-list-item-title(v-if='user.info.prenom && user.info.nom') {{user.info.prenom}} {{user.info.nom}}
        v-list-item-subtitle.grey--text.i(v-if='user.info.username') @{{user.info.username}}
      div.actions__wrapper
        v-menu(offset-y)
          template(v-slot:activator='{ on }')
            v-btn.mr2(icon,small, @click.prevent.stop='', v-on='on')
              font-awesome-icon.f6(:icon="['fas', 'ellipsis-v']")
          v-card.pv2
            v-list(dense, nav)
              v-list-item(@click='')
                font-awesome-icon.f6.mr2(:icon="['fad', 'wave-sine']", style='min-width: 24px')
                v-list-item-content
                  v-list-item-title Voir les résultats
              v-list-item(@click='connectToEnfant(user)')
                font-awesome-icon.f6.mr2(:icon="['fad', 'graduation-cap']", style='min-width: 24px')
                v-list-item-content
                  v-list-item-title Accéder au compte élève
              v-list-item(@click='goChat')
                font-awesome-icon.f6.mr2(:icon="['fad', 'comment-smile']", style='min-width: 24px')
                v-list-item-content
                  v-list-item-title Envoyer un message
              v-divider.mv2
              v-list-group(@click.stop='', color='secondary')
                template(v-slot:activator='')
                  v-list-item-content
                    v-list-item-title Actions
                template()
                  v-list-item(@click='')
                    v-list-item-title Ajouter à un groupe
                  v-list-item(@click='')
                    v-list-item-title Supprimer
        v-btn(fab, depressed, color='secondary', small, :to="{ path: 'enfants/' + user.id }")
          font-awesome-icon(:icon="['far', 'arrow-right']")
</template>
<style lang='sass' scoped>
@import 'src/styles/components/presentation/_elevePreview'

</style>
